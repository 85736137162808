import logo from '../..//media/Logo.webp';
import { Link, Outlet, Navigate, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Parse from 'parse/dist/parse.min.js';
import React, { useEffect, useState } from 'react'


function App() {

/* Deployment */
/* */
Parse.serverURL = 'http://hrsl.fortiddns.com:1337/parse';
Parse.initialize('SCWASRTWK9Y6AVMP3KFC_HRSL', 'AEvJgTDxZo6yo80mduCnsGCN3ZIhukr9') 
/* */

/* Development */
/*
Parse.serverURL = 'http://hrsl.fortiddns.com:1337/dev';
Parse.initialize('SCWASRTWK9Y6AVMP3KFC_TEST_HRSL', 'AEvJgTDxZo6yo80mduCnsGCN3ZIhukr9') 
*/
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [currentUser, setCurrentUser] = useState(undefined)
    const navigate = useNavigate();

    const login = async () => {
        try {
          setTimeout(async () => {
            const user = await Parse.User.logIn(username, password);
            if (user) {
              console.log(user)
              navigate('/home')
            }
          }, 100)
        }

        catch (error) {
            alert(`Error! ${error.message}`);
            return false;
          }
      }
    

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" width='100%' height='auto'/>
        <p>
          Bienvenido
        </p>
        <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Usuario</Form.Label>
        <Form.Control 
            type="email" 
            placeholder="ingresa tu usuario" 
            onChange={({target:{value}}) => setUsername(value)} 
            onKeyPress={event => {
                if (event.key === "Enter") {
                    login()
                }
            }}
            />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Contraseña</Form.Label>
        <Form.Control 
        type="password" 
        placeholder="Ingresa tu contraseña"  
        onChange={({target:{value}}) => setPassword(value)} 
        onKeyPress={event => {
            if (event.key === "Enter") {
                login()
            }
        }}
        />
      </Form.Group>
      </Form>
        <Button variant="primary" onClick={() => login()}>Iniciar sesión</Button>
        <Outlet />
      </header>
    </div>
  );
}

export default App;