import logo from './media/Logo.webp';
import './App.css';
import { Link, Outlet, Navigate, useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Parse from 'parse/dist/parse.min.js';
import React, { useState, useEffect } from 'react'
import SidebarMenu from 'react-bootstrap-sidebar-menu';

function App() {
  const [currentUser, setCurrentUser] = useState(undefined)
  
  const getCurrentUser = async () => {
    setCurrentUser(await Parse.User.current())
} 
const navigate = useNavigate()

getCurrentUser()
 
  return (
    <div className="App">
          {currentUser && <Navigate to="/home" replace={true} />}
      <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" width='100%' height='auto' />
        <p>
          Bienvenido
        </p>
        <Button variant="primary" onClick={() => navigate('/login')}>Iniciar sesión</Button>
        <Outlet />
      </header>
    </div>
  );
}

export default App;
