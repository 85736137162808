import logo from '../..//media/Logo.webp';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Parse from 'parse/dist/parse.min.js';
import React, { useEffect, useState } from 'react'
import Lottie from "lottie-react";
import scan from '../../media/scan.json'
import { decodificarJSON } from '../../utils/decodificarJSON';
import { buscarPaciente, buscarMedico, buscarUbicacion } from  '../../utils/buscarPaciente';
import { ListGroup } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import hacerIngreso from '../../utils/hacerIngreso';

function ConfirmacionIngreso() {
    console.log('ConfirmacionIngreso')

/* Deployment */
/* */
Parse.serverURL = 'http://hrsl.fortiddns.com:1337/parse';
Parse.initialize('SCWASRTWK9Y6AVMP3KFC_HRSL', 'AEvJgTDxZo6yo80mduCnsGCN3ZIhukr9') 
/* */

/* Development */
/*
Parse.serverURL = 'http://hrsl.fortiddns.com:1337/dev';
Parse.initialize('SCWASRTWK9Y6AVMP3KFC_TEST_HRSL', 'AEvJgTDxZo6yo80mduCnsGCN3ZIhukr9') 
*/

const [currentUser, setCurrentUser] = useState(true)
    const [warning, setWarning] = useState('')
    const [file, setFile] = useState(undefined)
    const [json, setJson] = useState(undefined)
    const [data, setData] = useState(undefined)
    const {state} = useLocation();
    const [medico, setMedico] = useState(state.medico)
    const [paciente, setPaciente] = useState(state.paciente)
    const [ubicaciones, setUbicaciones] = useState(undefined)
    const [ubicacion, setUbicacion] = useState('')
    const [tipo, setTipo] = useState('')
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    let seleccionUsuario = state.seleccionUsuario

    console.log('Paciente2', paciente)


    const [windowSize, setWindowSize] = useState(getWindowSize());


  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

    
    const getCurrentUser = async () => {
        setCurrentUser(await Parse.User.current())
    }

    getCurrentUser()

    const seleccionarTipoIngreso = async (tipoIngreso) => {
        const ubicaciones = await buscarUbicacion(tipoIngreso)
        setUbicaciones(ubicaciones)
        setUbicacion(undefined)
        setTipo(tipoIngreso)
    }
console.log(ubicacion)

const ejecutarIngreso = async () => {
    setTimeout(async () => {
        setLoading(true)
        try {
            const result = await hacerIngreso(paciente, medico, ubicacion, tipo, seleccionUsuario)
            result && navigate('/exitoIngreso')
        } catch (error) {
            console.log(error)
            setWarning('Error al hacer el ingreso')
        } finally {
            setLoading(false)
        }
    } , 100)
}
    

  return (
            <div className="App">
            <header className="App-header">
                {!currentUser && <Navigate to='/' replace='true' /> }
                <img src={logo} className="App-logo" alt="logo" width={windowSize.innerWidth*.8} height={windowSize.innerHeight*.2} />
                
                   
                {warning && 
                    <div class="alert alert-warning" role="alert">
                        {warning}
                    </div>
                }
                <Container>
                    <Row>
                        <Col>
                <Card>
                    <Card.Header>Paciente</Card.Header>
                    <ListGroup variant="flush">
                        <ListGroup.Item>{paciente.nombreCompleto}</ListGroup.Item>
                        <ListGroup.Item>{paciente.birthday}</ListGroup.Item>
                    </ListGroup>
                </Card>
                </Col>

                <Col>
                
                <Card>
                <Card.Header>Médico</Card.Header>
                    <ListGroup variant="flush">
                        <ListGroup.Item>{medico.nombreCompleto}</ListGroup.Item>
                        <ListGroup.Item>{medico.master}</ListGroup.Item>
                    </ListGroup>
                </Card>
                </Col>

                </Row>

                <Row>
                <Col>
                <Card>
                    <ButtonGroup aria-label="Basic example">
                        <Button onClick={() => seleccionarTipoIngreso('Cunero')} variant="secondary">Cunero</Button>
                        <Button onClick={() => seleccionarTipoIngreso('Cirugía ambulatoria')} variant="secondary">Cirugía ambulatoria</Button>
                        <Button onClick={() => seleccionarTipoIngreso('Cirugía mayor')} variant="secondary">Cirugía mayor</Button>
                        <Button onClick={() => seleccionarTipoIngreso('Urgencias')} variant="secondary">Urgencias</Button>
                        <Button onClick={() => seleccionarTipoIngreso('Hospitalización')} variant="secondary">Hospitalización</Button>
                    </ButtonGroup>
                    {ubicaciones && <ListGroup>
                        {ubicaciones.map((u) => 
                                <ListGroup.Item 
                                href={u.unique}
                                active={ubicacion?.unique === u.unique}
                                disabled={u.ocupadaPor}
                                onClick={(e) => 
                                    setTimeout(() => {
                                        const nuevaUbicacion = ubicaciones.filter((u) => u.unique === e.target.innerHTML)[0]
                                        setUbicacion(nuevaUbicacion)
                                    }, 100)}
                            >{u.unique}</ListGroup.Item>)}
                        </ListGroup>
                    }
                </Card>
                </Col>
                </Row>

                </Container>

                {ubicacion && <Button disabled={loading} onClick={() => ejecutarIngreso()} variant="primary">Confirmar</Button>}
                
                
             
               
                </header>
           
    </div>
  );
}

export default ConfirmacionIngreso;