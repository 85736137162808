import logo from '../..//media/Logo.webp';
import { Link, Outlet, Navigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Parse from 'parse/dist/parse.min.js';
import React, { useEffect, useState } from 'react'
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();


 //* Deployment */
 /* */
  Parse.serverURL = 'http://hrsl.fortiddns.com:1337/parse';
  Parse.initialize('SCWASRTWK9Y6AVMP3KFC_HRSL', 'AEvJgTDxZo6yo80mduCnsGCN3ZIhukr9') 
 /* */
    
  /* Development */
  /*
  Parse.serverURL = 'http://hrsl.fortiddns.com:1337/dev';
  Parse.initialize('SCWASRTWK9Y6AVMP3KFC_TEST_HRSL', 'AEvJgTDxZo6yo80mduCnsGCN3ZIhukr9') 
 */

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [currentUser, setCurrentUser] = useState(undefined)

    const login = async () => {
        const user = await Parse.User.logIn(username, password);
        console.log(user)
      }
    
    const getCurrentUser = async () => {
      const user = await Parse.User.current();
      console.log(user)
      if (user) setCurrentUser(user.toJSON())
    }
    useEffect(() => {
      getCurrentUser()
    }, [])

    const doLogOut = async () => {

        try {
            await Parse.User.logOut()
            getCurrentUser()
            navigate('/')
            
        }

        catch (error) {
            alert(`Error! ${error.message}`);
            return false;
          }
    }

    const nuevoIngreso = () => {
        return (
            navigate('../nuevoIngreso')
        )
    }



  return (
            <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" width='100%' height='auto' />
                <p>
                Bienvenido, {currentUser?.names && currentUser.names}
                </p>
                <Button onClick={() => nuevoIngreso()} > Nuevo ingreso </Button>
                <Button variant="danger" onClick={() => doLogOut()} >Cerrar sesión</Button>
            </header>
           
    </div>
  );
}

export default App;